import * as React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import BodyClassName from "react-body-classname"

const IndexPage: React.FC = () => {
  return (
    <>
      <BodyClassName className="bg-yellow-200">
        <main className="flex h-screen">
          <Helmet>
            <title>Publitools</title>
          </Helmet>
          <div className="max-w-xs w-full m-auto text-center">
            <Link
              to="/keywords"
              className="text-3xl text-black hover:text-gray-500 transition-colors"
            >
              Keywords
            </Link>
          </div>
        </main>
      </BodyClassName>
    </>
  )
}

export default IndexPage
